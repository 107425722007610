import { cache } from 'react';
import fetcher from 'utils/fetcher';
import EVENTSQUERY from 'models/Events/EventsQuery';
import EventsType from 'models/Events/EventsType';

export const fetchHomeEvents = cache(async () => {
  let data,
    errorCode = null;
  const page = 1;
  const contentAreaSlug =
    process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG || 'the-current';

  const vars = {
    contentAreaSlug,
    page
  };

  try {
    data = (await fetcher(EVENTSQUERY, vars)) as EventsType;
    return {
      props: {
        data: data.events.items,
        errorCode
      }
    };
  } catch (err) {
    console.error(
      `❌ Error on getProfileData at (routes)/profile/[...slug]/page.tsx`
    );
    errorCode = 404;
  }
  return {
    props: {
      data,
      errorCode
    }
  };
});
