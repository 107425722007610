'use client';
import { FC, useEffect, useState, useRef } from 'react';
import sty from 'styles/modules/LiveInStudio.module.css';
import { Link } from '@apmg/titan';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import fetcher from 'utils/fetcher';
import COLLECTIONQUERY from 'models/Collection/CollectionQuery';
import CollectionType from 'models/Collection/CollectionType';
import Loading from 'components/Loading/Loading';
import CmsContentType from 'models/Content/CmsPostType';
interface Props {
  isHomepage?: boolean;
  title?: string;
  description?: string;
  slug?: string;
  video?: any;
  resourceType?: string;
}

interface LightCollection {
  title: string;
  canonicalSlug: string;
}
interface CmsContentTypeWPrimaryCollection extends CmsContentType {
  primaryCollection?: LightCollection;
}

const LiveInStudio: FC<Props> = ({
  isHomepage = true,
  title,
  description,
  video
}) => {
  const [story, setStory] = useState<CmsContentTypeWPrimaryCollection>();
  const videoRef = useRef(null);
  async function getData() {
    const results = await fetcher<CollectionType>(COLLECTIONQUERY, {
      contentAreaSlug: process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG,
      slug: 'sessions'
    });
    setStory(results.collection.results.items[0]);
  }

  if (isHomepage) {
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          getData();
        }
      });
      if (videoRef.current) observer.observe(videoRef.current);
      return () => {
        if (videoRef.current) observer.unobserve(videoRef.current);
      };
    }, []);
  }

  return (
    <div
      className={`${isHomepage && `${sty.liveInStudio}`}`}
      ref={videoRef && videoRef}
    >
      <div className={`${isHomepage && `${sty.liveInStudioOverlay}`}`}>
        {story || title ? (
          <div className="container">
            <div className={sty.liveInStudio_container}>
              <div className={sty.liveInStudio_details}>
                <div>
                  {story?.primaryCollection && (
                    <div className={sty.featured_badge}>
                      <Link href={story?.primaryCollection.canonicalSlug}>
                        {story?.primaryCollection.title}
                      </Link>
                    </div>
                  )}
                  <div className={sty.liveInStudio_subTitle}>
                    {story && story.title ? story.title : title}
                  </div>
                  <p>
                    {story && story.descriptionText
                      ? story.descriptionText
                      : description}
                  </p>
                </div>
              </div>
              <div className={sty.liveInStudio_video}>
                <div>
                  <VideoPlayer
                    video={
                      story && story.primaryVisuals.video
                        ? story.primaryVisuals.video
                        : video
                    }
                    key={
                      story && story?.primaryVisuals?.video?.url
                        ? story.primaryVisuals.video.url
                        : video
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default LiveInStudio;
