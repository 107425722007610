'use client';
import { FC, Key, useState, useRef, useEffect } from 'react';
import sty from 'styles/modules/TheGigList.module.css';
import { Image } from '@apmg/mimas';
import Link from 'next/link';
import { getItemLink } from 'utils';
import { EventContent } from 'models/Event/EventType';
import IconCalendar from 'components/Icon/IconCalendar';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { fetchHomeEvents } from 'server/Home/fetchHomeEvents';
import Loading from 'components/Loading/Loading';
import { Thumbnail } from 'components/Tile';
import ImageShape from 'config/defaultData/ImageShape';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  events?: EventContent[];
}

const TheGigList: FC<Props> = () => {
  const [events, setEvents] = useState<any>();
  const eventsRef = useRef(null);
  const parseLocation = (location: string) => {
    let results;
    location.match(/,/)
      ? (results = location.split(',')[0])
      : (results = location);
    return results;
  };

  function fetchdata() {
    let data;
    if (!events) data = fetchHomeEvents();
    data &&
      data.then((results) => {
        setEvents(results.props.data);
      });
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !events) {
        fetchdata();
      }
    });
    if (eventsRef.current) observer.observe(eventsRef.current);
    return () => {
      if (eventsRef.current) observer.unobserve(eventsRef.current);
    };
  });

  const formatEventDate = (event: EventContent) => {
    const results = event.occurrences.map(
      (
        occurrence: { startTime: string | null | undefined },
        i: number | boolean
      ) => (
        <span key={occurrence.startTime}>
          {i === 0 && (
            <>
              {dayjs(occurrence.startTime)
                .tz('America/Chicago')
                .format('MMM D')}
            </>
          )}
          {i == !0 && event.occurrences.length > 1 && (
            <>
              {' - '}
              {dayjs(event.occurrences[event.occurrences.length - 1].startTime)
                .tz('America/Chicago')
                .format('D')}
            </>
          )}
        </span>
      )
    );

    return results;
  };

  return (
    <div className={sty.gigList} ref={eventsRef}>
      {events ? (
        <div className="container">
          <section>
            <div className={sty.gigList_container}>
              <div className={sty.gigList_header}>
                <div>
                  <h2>The Gig List</h2>
                  Check out the upcoming events
                </div>
                <div className={sty.gigList_header_link}>
                  <Link
                    className="btn btn-primary"
                    href={'/events'}
                    prefetch={false}
                    passHref
                  >
                    <IconCalendar width="20" height="20" />
                    <span>View the Gig List Calendar</span>
                  </Link>
                </div>
              </div>
              <div className={sty.gigList_content}>
                <div className={sty.gigList_content_left_overlay}>
                  <div className={sty.gigList_content_left}>
                    <div className={sty.gigList_content_left_date}>
                      {formatEventDate(events[0])}
                    </div>
                    <div className={sty.gigList_content_left_details}>
                      <div className={sty.gigList_content_left_details_image}>
                        <Link
                          href={getItemLink(events[0]).href}
                          prefetch={false}
                          passHref
                        >
                          <Image
                            image={events[0].primaryVisuals.lead}
                            aspectRatio="normal"
                            alt={
                              events[0].primaryVisuals.thumbnail?.shortCaption
                            }
                            loading="lazy"
                          />
                        </Link>
                      </div>
                      <div
                        className={sty.gigList_content_left_details_container}
                      >
                        {events[0].collections?.filter(
                          (collection: { canonicalSlug: string }) =>
                            collection.canonicalSlug ===
                            'current-sponsored-events'
                        ).length > 0 && (
                          <div className={sty.currentPresents}>
                            <span>The Current presents</span>
                          </div>
                        )}
                        <div
                          className={
                            sty.gigList_content_left_details_container_title
                          }
                        >
                          <Link
                            href={getItemLink(events[0]).href}
                            prefetch={false}
                            passHref
                          >
                            <h3>{events[0].title}</h3>
                          </Link>
                        </div>
                        <div
                          className={
                            sty.gigList_content_left_details_container_description
                          }
                        >
                          <p>{events[0].descriptionText}</p>
                        </div>

                        {events[0].occurrences.map(
                          (occurrence: {
                            startTime: string | null | undefined;
                          }) => (
                            <p
                              key={occurrence.startTime}
                              className="type-snug-up type-snug-down"
                            >
                              {dayjs(occurrence.startTime)
                                .tz('America/Chicago')
                                .format('dddd, MMMM D, YYYY h:mm A')}
                            </p>
                          )
                        )}
                        <div
                          className={
                            sty.gigList_content_left_details_container_link
                          }
                        >
                          <button className="btn btn-slim">
                            <Link
                              href={getItemLink(events[0]).href}
                              prefetch={false}
                              passHref
                            >
                              More Information
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={sty.gigList_content_right}>
                  {events
                    .slice(1, 4)
                    .map((event: EventContent, index: number) => {
                      const location = parseLocation(event.location);
                      const isSponsored =
                        event.collections?.filter(
                          (collection) =>
                            collection.canonicalSlug ===
                            'current-sponsored-events'
                        ).length > 0;
                      return (
                        <div
                          className={sty.gigList_content_details}
                          key={index}
                        >
                          <div className={sty.gigList_content_image}>
                            <Link
                              href={getItemLink(event).href}
                              prefetch={false}
                              passHref
                            >
                              {event.primaryVisuals?.thumbnail ? (
                                <Thumbnail
                                  alt={
                                    event.primaryVisuals.thumbnail.shortCaption
                                  }
                                  dimension={ImageShape.normal.name}
                                  img={event.primaryVisuals.thumbnail}
                                  key={event.title}
                                />
                              ) : (
                                <img
                                  src="/images/default-event.jpg"
                                  className="tile-grid-image"
                                  height="135"
                                  width="240"
                                  alt="The Current: Events"
                                />
                              )}
                            </Link>
                          </div>
                          <div className={sty.gigList_content_details_inner}>
                            <div className={sty.inner_content_date}>
                              {formatEventDate(event)}
                            </div>
                            {isSponsored && (
                              <div className={sty.currentPresents}>
                                <span>The Current presents</span>
                              </div>
                            )}
                            <div className={sty.inner_content_title}>
                              <Link
                                href={getItemLink(event).href}
                                prefetch={false}
                                passHref
                              >
                                {event.title}
                              </Link>
                            </div>
                            <div
                              className={sty.inner_content_location_container}
                            >
                              <div
                                key={event.occurrences[0].startTime}
                                className={`type-snug-up type-snug-down ${sty.inner_content_location_time}`}
                              >
                                {dayjs(event.occurrences[0].startTime)
                                  .tz('America/Chicago')
                                  .format('h:mm a')}
                                .
                              </div>

                              {location?.length > 0 && (
                                <div className="hide-on-mobile">|</div>
                              )}
                              <div className={sty.inner_content_location}>
                                {location}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default TheGigList;
