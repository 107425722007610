'use client';
import React, { FC, useContext, useEffect, useState } from 'react';
import { RequestDocument } from 'graphql-request';
import Teasers, { TeasersProps } from 'components/Teasers/Teasers';
import fetcher from 'utils/fetcher';
import CollectionType from 'models/Collection/CollectionType';
import { TileData } from 'components/Teasers/type';
import { AudioContext } from 'context/AudioContext';
import sty from 'styles/modules/TeasersWrapper.module.css';
import ImageShape from 'config/defaultData/ImageShape';

interface Props extends TeasersProps {
  query: RequestDocument;
}

const TeasersWrapper: FC<Props> = ({ query, ...rest }) => {
  const [data, setData] = useState<TileData[] | undefined>(undefined);
  const { programId } = useContext(AudioContext);

  async function fetchData(vars: Record<string, unknown>) {
    const collection = await fetcher<CollectionType>(query, vars);
    setData(collection.collection.results.items);
  }

  const slugMap: Record<string, string> = {
    'the-current': 'the-current-player-stories',
    'carbon-sound': 'carbon-sound-player-stories',
    'radio-heartland': 'radio-heartland-player-stories'
  };

  useEffect(() => {
    fetchData({
      slug: slugMap[programId ?? 'the-current'] ?? 'the-current-player-stories',
      contentAreaSlug:
        process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG ?? 'the-current'
    });
  }, [query, programId]);

  return (
    <Teasers
      {...rest}
      data={data}
      classes={sty.teasersWrapper}
      extendOffPageEdge={false}
      arrowsAboveItems={false}
      tileHeadingElement="h3"
      redArrowButtons={true}
      imageShape={ImageShape.normal}
      playButtonsNextToImages={true}
    />
  );
};

export default TeasersWrapper;
