import { gql } from 'graphql-request';

import PrimaryVisualsFragment from '../PrimaryVisuals/PrimaryVisualsFragment';

const QUERY = gql`
query Events($contentAreaSlug: String!, $page: Int!) {
  events: events(contentAreaSlug: $contentAreaSlug, page: $page, pageSize: 10) {
    nextPage
    previousPage
    items {
      id
      title
      subtitle
      templateName
      resourceType
      canonicalSlug
      descriptionText
      publishDate
      location
      venue
      occurrences {
        startTime
        endTime
      }
      primaryVisuals {
        ${PrimaryVisualsFragment}
      }
      collections {
        canonicalSlug
      }
    }
  }
}
`;

export default QUERY;
