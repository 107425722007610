'use client';
import { FC, useEffect, useState, useRef } from 'react';
import Teasers from 'components/Teasers';
import ImageShape from 'config/defaultData/ImageShape';
import fetcher from 'utils/fetcher';
import COLLECTIONQUERY from 'models/Collection/CollectionQuery';
import CollectionType, {
  CollectionItem
} from 'models/Collection/CollectionType';
import Loading from 'components/Loading/Loading';

const MusicNews: FC = () => {
  const [stories, setStories] = useState<CollectionItem[] | undefined>();
  const storiesRef = useRef(null);

  async function getData() {
    const news = await fetcher<CollectionType>(COLLECTIONQUERY, {
      contentAreaSlug: process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG,
      slug: 'home-stories'
    });
    setStories(news.collection.results.items);
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (storiesRef.current) observer.observe(storiesRef.current);
    return () => {
      if (storiesRef.current) observer.unobserve(storiesRef.current);
    };
  }, []);

  return (
    <section id="stories" ref={storiesRef}>
      {stories ? (
        <Teasers
          data={stories}
          imageShape={ImageShape.normal}
          title="Music News"
          subheadingText=""
          // viewAllRoute={getItemLink(stories)}
          viewAllTitle="View All News"
          extendOffPageEdge
          showContributors={true}
          showDate={true}
          shareIcons={false}
          headingElement="h2"
          tileHeadingElement="h3"
        />
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default MusicNews;
