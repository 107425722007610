'use client';
import React, {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useState
} from 'react';
import sty from 'styles/modules/DonateHomepage.module.css';
import { NodeData } from 'types/NodeData';
import IconRockOn from 'components/Icon/IconRockOn';

interface Props {
  elementClass?: string;
  nodeData?: NodeData;
  size?: string;
}

const DonateHomepage: FC<Props> = (props) => {
  const [donation, setDonation] = useState({
    type: 'monthly',
    amount: '15'
  });

  const [isActive, setActive] = useState(false);

  const containerClass = props?.nodeData?.attrs?.size
    ? `${sty.donateAskForm} ${sty.donateAskForm}-${props.nodeData.attrs.size}`
    : `${sty.donateAskForm} ${props.elementClass}`;

  const handleDonationType: ChangeEventHandler<HTMLInputElement> = (event) => {
    const type = event.currentTarget?.value;
    setDonation({
      type,
      amount: ''
    });
  };

  const handleAmountClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const dataAmount = event.currentTarget.getAttribute('data-amount');
    if (dataAmount && Number.parseInt(dataAmount)) {
      setDonation((prevValue) => {
        return { ...prevValue, amount: dataAmount };
      });
      setActive(!isActive);
    } else {
      const child = event.currentTarget.firstElementChild;
      const amount = (child as HTMLInputElement)?.value;
      setDonation((prevValue) => {
        return { ...prevValue, amount };
      });
      setActive(!isActive);
    }
    event.currentTarget.setAttribute('data-checked', 'true');
  };

  const handleCustomAmount: ChangeEventHandler<HTMLInputElement> = (event) => {
    const amount = event.currentTarget?.value;
    setDonation((prevValue) => {
      return { ...prevValue, amount };
    });
  };

  return (
    <div className={containerClass}>
      <div className="wrapper">
        <div className={sty.formContainer}>
          <h2 className={sty.formHeader}>
            <IconRockOn height="2rem" width="2rem" className={sty.formLogo} />
            <div>We’re Powered by You!</div>
          </h2>
          <div className={`${sty.formSubHeader} hide-on-desktop`}>
            Listener-Supported Music
          </div>
          <p>
            The Current is public media that amplifies independent music and
            discovers emerging talent. The Current depends on{' '}
            <strong>your</strong> support. Give now to power music discovery for
            all.
          </p>
          <div className="hide-on-mobile">
            <div className={sty.formRadioContainer} id="askFormRecurs">
              <div>
                <label htmlFor="recurs-monthly">
                  <input
                    name="monthly"
                    type="radio"
                    id="recurs-monthly"
                    className={sty.formRadio}
                    checked={donation.type === 'monthly'}
                    value="monthly"
                    onChange={handleDonationType}
                  />{' '}
                  Monthly
                </label>
              </div>
              <div>
                <label htmlFor="recurs-one-time">
                  <input
                    name="one-time"
                    type="radio"
                    id="recurs-one-time"
                    className={sty.formRadio}
                    value="one-time"
                    checked={donation.type !== 'monthly'}
                    onChange={handleDonationType}
                  />{' '}
                  One-Time
                </label>
              </div>
            </div>
            {donation.type === 'monthly' && (
              <div className={sty.formDonateSelection} id="askFormMonthly">
                <div className={sty.formDonateSelectionItems}>
                  <div
                    id="amount-monthly-5"
                    className={`${sty.amount} ${
                      donation.amount == '5' && `${sty.active}`
                    }`}
                    data-recurs="monthly"
                    data-amount="5"
                    data-checked={donation.amount == '5'}
                    onClick={handleAmountClick}
                  >
                    $5/mo.
                  </div>

                  <div
                    id="amount-monthly-10"
                    className={`${sty.amount} ${
                      donation.amount == '10' && `${sty.active}`
                    }`}
                    data-recurs="monthly"
                    data-amount="10"
                    data-checked={donation.amount == '10'}
                    onClick={handleAmountClick}
                  >
                    $10/mo.
                  </div>
                  <div
                    id="amount-monthly-15"
                    className={`${sty.amount} ${
                      donation.amount == '15' && `${sty.active}`
                    }`}
                    data-recurs="monthly"
                    data-amount="15"
                    data-checked={donation.amount == '15'}
                    onClick={handleAmountClick}
                  >
                    $15/mo.
                  </div>
                  <div
                    id="amount-monthly-20"
                    className={`${sty.amount} ${
                      donation.amount == '20' && `${sty.active}`
                    }`}
                    data-recurs="monthly"
                    data-amount="20"
                    data-checked={donation.amount == '20'}
                    onClick={handleAmountClick}
                  >
                    $20/mo.
                  </div>
                  <label className={sty.amountOtherText}>Other: </label>
                  <div id="amount-monthly-other" className={sty.amountOther}>
                    <span>$</span>
                    <input
                      type="text"
                      inputMode="numeric"
                      name="amount-monthly-other-value"
                      id="amount-monthly-other-value"
                      onChange={handleCustomAmount}
                    />
                  </div>
                </div>
              </div>
            )}
            {donation.type !== 'monthly' && (
              <div className={sty.formDonateSelection}>
                <div
                  className={sty.formDonateSelectionItems}
                  id="askFormOneTime"
                >
                  <div
                    id="amount-one-time-60"
                    className={`${sty.amount} ${
                      donation.amount == '60' && `${sty.active}`
                    }`}
                    data-recurs="one-time"
                    data-amount="60"
                    data-checked={donation.amount == '60'}
                    onClick={handleAmountClick}
                  >
                    $60
                  </div>
                  <div
                    id="amount-one-time-120"
                    className={`${sty.amount} ${
                      donation.amount == '120' && `${sty.active}`
                    }`}
                    data-recurs="one-time"
                    data-amount="120"
                    data-checked={donation.amount == '120'}
                    onClick={handleAmountClick}
                  >
                    $120
                  </div>
                  <div
                    id="amount-one-time-180"
                    className={`${sty.amount} ${
                      donation.amount == '180' && `${sty.active}`
                    }`}
                    data-recurs="one-time"
                    data-amount="180"
                    data-checked={donation.amount == '180'}
                    onClick={handleAmountClick}
                  >
                    $180
                  </div>
                  <div
                    id="amount-one-time-240"
                    className={`${sty.amount} ${
                      donation.amount == '240' && `${sty.active}`
                    }`}
                    data-recurs="one-time"
                    data-amount="240"
                    data-checked={donation.amount == '240'}
                    onClick={handleAmountClick}
                  >
                    $240
                  </div>

                  <label className={sty.amountOtherText}>Other: </label>
                  <div id="amount-one-time-other" className={sty.amountOther}>
                    <span>$</span>
                    <input
                      type="text"
                      inputMode="numeric"
                      name="amount-one-time-other-value"
                      id="amount-one-time-other-value"
                      onChange={handleCustomAmount}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={sty.submitButtonContainer}>
            <form
              action="https://support.mpr.org/current-web"
              target="_blank"
              method="get"
            >
              <input type="hidden" name="amount" value={donation.amount} />
              <input type="hidden" name="recurs" value={donation.type} />
              <div>
                <button
                  className={`btn btn-primary ${sty.askButton} hide-on-mobile`}
                  disabled={isNaN(Number.parseInt(donation.amount))}
                >
                  Make a Donation
                </button>
                <button
                  className={`btn btn-primary ${sty.askButton} hide-on-desktop`}
                  disabled={isNaN(Number.parseInt(donation.amount))}
                >
                  Donate Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateHomepage;
